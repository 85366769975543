<template>
  <div>
    <b-card-code title="Upload Acunetix Report">
      <div class="mb-3">
        <h6>Sample files : 
          <a 
            href= "files/sample_acunetix_report.csv"
            download>
            Click here to download sample file.
          </a> 
        </h6>
      </div>
      <validation-observer ref="AcunetixUploadForm" #default="{ invalid }">
        <b-form class="auth-login-form mt-2" @submit.prevent="uploadReport">
          <!--Upload File -->
          <b-form-group label="Upload File" label-for="File">
            <b-form-file
              v-model="acunetix_report"
              :state="Boolean(acunetix_report)"
              ref="file"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              v-on:change="handleFileUpload()"
            ></b-form-file>
            <div class="mt-3">
              Selected file: {{ acunetix_report ? acunetix_report.name : "" }}
            </div>
          </b-form-group>

          <b-button type="submit" variant="primary" :disabled="invalid">
            Upload
          </b-button>
        </b-form>
      </validation-observer>
    </b-card-code>

    <b-card-code no-body title="Uploads">
      <b-table
        responsive
        id="AcunetixuploadsTable"
        :fields="fields"
        :items="items"
        :per-page="perPage"
        hover
      >
        <template #cell(is_processed)="data">
          <b-badge variant="primary" v-if="data.item.is_processed == 0"
            >Queued</b-badge
          >
          <b-badge variant="warning" v-if="data.item.is_processed == 1"
            >In Progress</b-badge
          >
          <b-badge variant="success" v-if="data.item.is_processed == 2"
            >Processed</b-badge
          >
          <b-badge variant="danger" v-if="data.item.is_processed == 3"
            >Failed</b-badge
          >
        </template>
        <template #cell(stats)="data">
          <b-badge v-if="data.item.is_processed != 0" variant="success"
            >New Assets: {{ data.item.assets_new }}</b-badge
          ><br />
          <b-badge v-if="data.item.is_processed != 0" variant="secondary"
            >Duplicate Assets: {{ data.item.assets_dpl }}</b-badge
          ><br />
          <b-badge v-if="data.item.is_processed != 0" variant="success"
            >New Vulnerabilities: {{ data.item.vulnerabilities_new }}</b-badge
          ><br />
          <b-badge v-if="data.item.is_processed != 0" variant="secondary"
            >Vulnerabilities Retained:
            {{ data.item.vulnerabilities_dpl }}</b-badge
          >
        </template>
        <template #cell(actions)="data">
          <button
            @click="deleteUpload(parseInt(data.item.acunetix_report_id))"
            type="button"
            class="btn ml-auto btn-danger btn-sm"
          >
            Delete
          </button>
        </template>
      </b-table>

      <b-pagination-nav
        v-model="currentPage"
        :numberOfPages="total"
        :link-gen="linkGen"
        :total-rows="rows"
        :per-page="perPage"
        :align="pagination_pos"
        aria-controls="AcunetixuploadsTable"
        use-router
      />
    </b-card-code>
  </div>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormCheckbox,
  BFormFile,
  BTable,
  BPaginationNav,
  BBadge,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox,
    BTabs,
    BTab,
    BFormFile,
    BTable,
    BPaginationNav,
    BBadge,
  },
  data() {
    return {
      // is_staff: this.$store.state.app.user.is_staff,
      acunetix_report: null,
      asset_id:null,
      pagination_pos: "center",
      items: [],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      total: 1,
      fields: [
        { key: "acunetix_report_id", label: "ID" },
        { key: "user_id.username", label: "User" },
        { key: "asset_id.asset_name", label: "Asset Name" },
        { key: "is_processed", label: "Status" },
        { key: "vulnerabilities_new", label: "New Vulnerabilities" },
        { key: "vulnerabilities_dpl", label: "Existing Vulnerabilities" },
        { key: "error", label: "Error" },
        { key: "actions", label: "Actions" },
      ],
    };
  },
  created: function () {
    this.asset_id = this.$route.params.asset_id;
    this.load();
  },
  watch: {
    currentPage: function (newVal, oldVal) {
      this.loadUploads();
    },
  },
  methods: {
    loadUploads: function () {
      let url = process.env.VUE_APP_BASEURL + "asset/acunetix-uploads?query=true";
      if (this.$route.query.page) this.currentPage = this.$route.query.page;
      url = url + "&page=" + this.currentPage;
      url = url + "&asset_id=" + this.asset_id;

      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        console.log("normal api --- ",res.data.results);
        this.items = res.data.results;
        this.rows = res.data.results.length;
        if(res.data.count != 0){
          this.total = Math.ceil(res.data.count / this.perPage);
        }
      });
    },
    linkGen: function (pageNum) {
      return this.$router.currentRoute.path + "?page=" + pageNum;
    },
    load: function () {
      this.loadUploads();
    },
    
    uploadReport: function () {
      this.$refs.AcunetixUploadForm.validate().then((success) => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Processing your request. Please wait!",
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "success",
            },
          });
          //call to axios
          let formData = new FormData();
          formData.append("file", this.acunetix_report);
          formData.append("asset_id", this.asset_id);

          const options = {
            method: "POST",
            headers: { "content-type": "multipart/form-data" },
            data: formData,
            url: process.env.VUE_APP_BASEURL + "asset/org-asset/upload-acunetix/",
          };
          var self = this;
          this.$http(options).then((res) => {
            self.loadUploads();
            console.log("acunetix upload result ----",res);
            var toast_title = "Successfully queued the request. ";
            var toast_variant = "success";
            if (res.data.status == "success") {
              //   this.$router.push({name: 'Assets'});
            } else {
              toast_title = res.data.message;
              toast_variant = "danger";
            }
            self.$toast({
              component: ToastificationContent,
              props: {
                title: toast_title,
                autoHideDelay: 5000,
                icon: "EditIcon",
                variant: toast_variant,
              },
            });
          });
        }
      });
    },
    handleFileUpload() {
      this.acunetix_report = this.$refs.file.files[0];
    },
    deleteUpload(id) {
      if (confirm("Do you really want to delete?")) {
        const options = {
          method: "DELETE",
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_BASEURL + "asset/acunetix-uploads/" + id + "/",
        };
        this.$http(options).then((res) => {
          console.log(res);
          this.loadUploads();
        })
      }
    },
  },
};
</script>